import { io } from 'socket.io-client'
import CONFIG from '../_services/config'

const BASE_URL = 'wss://' + CONFIG.apiUrl.replace('https://', '')
const APP_NAME = 'FONIO'

let socket = null

const initSocket = (token) => {
  socket = io(BASE_URL, {
    // transports: ['websocket', 'polling'],
    extraHeaders: {
      Authorization: `Bearer ${token}`
    }
  })
  socket.once('connect', () => {
    console.log(`[${APP_NAME}] Socket Connected`)
    socket.emit('events_subscription', {
      enable: true
    })
  })
  socket.once('disconnect', () => {
    console.log(`[${APP_NAME}] Socket Disconnected`)
  })

  socket.on('connect_error', (err) => {
    console.log(err)
    console.error('Connection error:', typeof err === 'string' ? JSON.parse(err) : err)
  })
}
const closeSocket = () => {
  if (socket) {
    socket.close()
  }
}

export {
  initSocket, socket,
  closeSocket
}
