<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    :return-value.sync="menu"
    transition="scale-transition"
    append-icon="schedule"
    offset-y
    bottom
    left
    min-width="auto"
    class="menu-mobile"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        solo
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
        :class="innerClass"
        class="grey-button px-2 w-100"
      >
        <v-icon size="14px" color="#637381" class="mr-1">schedule</v-icon>
        <span class="mr-auto">{{ dateComputed }}</span>
      </v-btn>
    </template>
    <div class="d-flex">
      <v-date-picker
        v-model="date"
        :picker-date.sync="pickerDate"
        :max="enableFuture ? null : today"
        :type="dateType === 'month' ? 'month' : 'date'"
        no-title
        scrollable
        @change="menu = false"
      />
    </div>
  </v-menu>
</template>

<script>
import months from '@/imports/months'
import moment from 'moment'
export default {
  name: 'DatePickerSolo',
  props: {
    dateType: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      required: false
    },
    pickerType: {
      type: String,
      default: ''
    },
    innerClass: {
      type: String,
      default: ''
    },
    enableFuture: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    pickerDate: null,
    date: moment(new Date()).format('YYYY-MM-DD'),
    menu: false,
    months
  }),
  computed: {
    dateComputed () {
      if (this.dateType !== 'month') return this.value ? this.value.toString() : this.date.toString()

      const [sYear, sMonth, sDay] = this.date.split('-')
      const intStartMonth = parseInt(sMonth) - 1

      return `${this.months[intStartMonth]} ${sYear}`
    },
    today () {
      return moment(new Date()).format('YYYY-MM-DD')
    }
  },
  watch: {
    date (val) {
      if (!this.enableFuture && new Date(this.date) > new Date()) this.date = moment(new Date()).format('YYYY-MM-DD')

      if (this.dateType === 'month' && this.pickerType) {
        const [y, m] = this.date.split('-')
        if (this.pickerType === 'start') {
          const first = moment(new Date(y, parseInt(m) - 1, 1, 0, 0, 0)).format('YYYY-MM-DD HH:mm:ss')
          return this.$emit('changeDate', first)
        } else if (this.pickerType === 'end') {
          const last = moment(new Date(y, parseInt(m), 0, 23, 59, 59)).format('YYYY-MM-DD HH:mm:ss')
          return this.$emit('changeDate', last)
        }
      }
      this.$emit('changeDate', this.date)
    },
    menu (val) {
      const today = new Date().toISOString().split('T')[0]
      this.pickerDate = today
    },
    value () {
      this.date = this.value
      this.pickerDate = this.value
    }
  },
  methods: {
    reset () {
      this.date = moment(new Date()).format('YYYY-MM-DD')
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content {
  @media (max-width: 959px) {
    right: 0 !important;
    left: 0 !important;
    overflow-x: auto !important;
    max-width: 100% !important;
    margin-top: 12px !important;
  }
}

.v-picker::v-deep .v-date-picker-table {
  table {
    border-collapse: collapse !important;
  }
  td {
    border: 1px solid #e4e4e4 !important;
  }
  tbody td {
    .v-btn {
      border-radius: 0 !important;
      width: 100% !important;
      color: #6770a1 !important;
      &:focus, &:hover {
        border-radius: 0 !important;
      }
      &.v-btn--disabled .v-btn__content {
        color: gray !important;
      }
    }
    .v-btn--active {
      &.v-date-picker-table__current {
        background-color: #6170C5 !important;
        color: white !important;
      }
      background-color: #6161c547 !important;
      border-radius: 0 !important;
    }
  }
}
</style>
