<template>
  <transition>
    <div class="pt-md-3 popup-wrapper">
      <v-card
        class="fz-14 mb-5 overflow-hidden d-flex flex-column"
        max-width="380px"
        max-height="100%"
        style="box-shadow: 0 0 0 1px rgba(6,44,82,0.1), 0 1px 3px 0 rgba(64,66,69,0.12), 0 2px 16px 0 rgba(33,43,54,0.08);"
        tile
      >
        <v-card-title
          class="pa-4 pb-0 fz-12 text-uppercase font-weight-bold dark-text"
          style="line-height: 16px;"
        >
          {{ callParticipants
            ? 'Call Participants'
              : callflowPage
              ? 'My callflows'
                : $route.path === '/phone-numbers'
                ? 'Members'
                  : $route.path === '/mailboxes'
                    ? 'Allowed users' : 'My contacts'
          }}
          <v-btn
            icon
            class="ml-auto d-md-none"
            @click="close"
          >
            <v-icon size="24px">close</v-icon>
          </v-btn>
        </v-card-title>

        <v-col
          cols="12"
          md="12"
          class="px-4 py-2"
          v-if="!callParticipants"
        >
          <v-text-field
            solo
            outlined
            :label="callflowPage ? 'Search callflows' : 'Search people'"
            type="text"
            v-model="search"
            dense
            hide-details
            class="search-box"
          >
            <template v-slot:prepend-inner>
              <v-icon size="20px" color="#919EAB">search</v-icon>
            </template>
          </v-text-field>
        </v-col>

        <v-list
          v-if="callflowPage"
          max-height="744px"
          style="overflow-y: auto;"
          class="pb-0"
        >
          <template v-for="(callflow, index) in callflowsList">
            <v-list-item :key="index + callflow.name" class="d-flex">
              <v-list-item-content>

                <v-list-item-title
                  class="fz-14 font-weight-medium"
                  style="color: #1D2428; letter-spacing: -0.15px; white-space: nowrap"
                >
                  {{ callflow.name }}
                </v-list-item-title>
                <!-- <v-list-item-subtitle
                  v-if="$route.path === '/phone-numbers'"
                  class="py-1"
                  style="color: #637381;"
                >
                  Assigned to {{ callflow.did.length }} numbers
                </v-list-item-subtitle> -->
              </v-list-item-content>
              <v-list-item-action
                style="min-width: unset;"
              >
                <v-btn
                  class="px-2 grey-button d-flex align-center justify-start"
                  width="120px"
                  @click="add(callflow.uuid)"
                >
                  <v-icon
                    size="23px"
                    color="#919EAB"
                  >
                    {{ callflowPage ?
                      'content_copy' :
                      $route.path === '/phone-numbers' ?
                        (selectedDidNumber && selectedDidNumber.callFlowUuid === callflow.uuid ? 'indeterminate_check_box' : 'add_box') :
                        'phone' }}
                  </v-icon>
                  <v-icon
                      class="ml-n3 mt-n3"
                      x-small
                      color="#919EAB"
                      v-if="!callflowPage && $route.path !== '/phone-numbers'"
                    >person
                    </v-icon>
                  <span class="ml-1">
                    {{ callflowPage ? 'Copy From':
                      $route.path === '/phone-numbers' ?
                        (selectedDidNumber && selectedDidNumber.callFlowUuid === callflow.uuid ? 'Unassign' : 'Assign') :
                        'Assign'}}
                  </span>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
        <v-list
          v-else
          max-height="744px"
          style="overflow-y: auto;"
          class="pb-0"
        >
          <template v-for="(user, index) in usersListComputed">
            <v-list-item :key="index + user.firstName">
              <v-list-item-avatar
                width="40px"
                height="40px"
              >
                <v-img
                  :alt="`${user.firstName} avatar`"
                  :src="'https://picsum.photos/200' + '?random=' + index"
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-title
                class="fz-14 font-weight-medium"
                style="color: #1D2428; letter-spacing: -0.15px; white-space: nowrap"
              >
                {{ fullName(user.firstName, user.lastName) }}
              </v-list-item-title>

              <v-list-item-action
                style="min-width: unset;"
                v-if="!callParticipants"
              >
                <v-btn
                  v-if="!assignMember"
                  class="px-3 grey-button d-flex justify-center"
                  width="110px"
                  @click="add(user)"
                >
                  <v-icon
                    size="20px"
                    color="#919EAB"
                  >
                    {{ user.added || (selectedDidNumber && selectedDidNumber.userId === user.user_id) ? 'person_remove' : 'person_add' }}
                  </v-icon>
                  <span class="ml-2">
                    <span v-if="$route.path === '/phone-numbers'">
                      {{ selectedDidNumber && selectedDidNumber.userId === user.user_id ? 'Unassign' : 'Assign' }}
                    </span>
                    <span v-else>{{ user.added ? 'Remove' : 'Add' }}</span>
                  </span>
                </v-btn>

                <v-menu
                  v-else
                  bottom
                  left
                  max-width="293px"
                  :rounded="'1'"
                  :close-on-content-click="false"
                  offset-y
                  content-class="elevation-2"
                  class="menu"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="grey-button px-3"
                      v-on="on"
                    >
                      <v-icon
                        size="20px"
                        color="#919EAB"
                        class="mr-2"
                      >
                        phone
                      </v-icon>
                      <v-icon
                        class="ml-n5 mt-n3"
                        x-small
                        color="#919EAB"
                      >person
                      </v-icon>
                      <span class="ml-1">Assign</span>
                    </v-btn>
                  </template>

                  <v-card width="100vw">
                    <v-card-title class="fz-14 dark-text pa-3 pt-1 pb-0">Enter extension</v-card-title>

                    <v-col
                      cols="12"
                      md="12"
                      class="px-3 pt-0 pb-4"
                    >
                      <v-text-field
                        solo
                        outlined
                        label="Type an extension"
                        type="text"
                        v-model="extension"
                        dense
                        hide-details
                        style="height: 36px;"
                        class="v-input-appended-outer--specify"
                      >
                        <template v-slot:prepend-inner><span @click="extension = ''" style="cursor: pointer">×</span></template>

                        <template v-slot:append-outer>
                          <v-btn
                            class="violet-button"
                            text
                            @click="addAssign(user.uuid)"
                          >
                            Assign
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-card>
                </v-menu>
              </v-list-item-action>
            </v-list-item>

            <v-divider :key="index"></v-divider>
          </template>
        </v-list>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  name: 'LeftPopUpMyContacts',
  props: {
    assignMember: {
      type: Boolean,
      default: false
    },
    callParticipants: {
      type: Boolean,
      default: false
    },
    recentContacts: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    search: '',
    extension: '',
    usersList: [],
    callflowsList: []
  }),
  computed: {
    ...mapGetters('components', ['selectedMailbox', 'selectedDidNumber']),
    usersListComputed () {
      let usersList = this.usersList
      const mailbox = this.selectedMailbox
      if (mailbox && mailbox.users) {
        usersList = this.usersList.map(item => ({
          ...item,
          added: mailbox.users.includes(item.user_uuid)
        }))
      }
      if (this.search) {
        usersList = usersList.filter(e =>
          e.firstName.toLowerCase().includes(this.search.toLowerCase()) ||
          e.lastName.toLowerCase().includes(this.search.toLowerCase())
        )
      }
      return usersList
    },
    callflowPage () {
      return this.$route.name === 'CreateCallflow' || this.$route.name === 'EditCallflow'
    }
  },
  watch: {
    async search (val) {
      let action = 'getContacts'
      const params = {}

      if (this.$route.path === '/mailboxes') return
      if (this.callflowPage || this.$route.path === '/phone-numbers') {
        action = 'getCallflows'
        params.filter = val
      } else {
        params.name = val
      }

      await this.$store.dispatch(action, params)
        .then((res) => {
          switch (action) {
            case 'getCallflows':
              this.callflowsList = res.data.payload.items
              break
            case 'getContacts':
              this.usersList = res.data.contacts
              break
            default:
              this.usersList = res.data.members.map(item => ({
                ...item,
                firstName: item.user_first_name,
                lastName: item.user_last_name,
                uuid: item.user_uuid
              }))
              break
          }
        }).catch(err => {
          throw new Error(err)
        })
    }
  },
  created () {
    this.$route.path === '/mailboxes' || this.$route.path === '/phone-numbers'
      ? this.membersList()
      : this.callflowPage
        ? this.callflowList()
        : this.contactList()
  },
  methods: {
    ...mapMutations('components', ['setMailboxRenderKey', 'setSelectedMailbox', 'setSelectedDidNumber']),
    ...mapActions([
      'getMembers', 'getContacts', 'getCallflows', 'addUserToMailbox',
      'deleteUserFromMailbox', 'addCallflowToDid', 'removeCallflowFromDid',
      'getCallflow', 'assignNumberToMember', 'releaseUserNumber'
    ]),
    async contactList () {
      try {
        const { data } = await this.getContacts()
        this.usersList = data.contactList
      } catch (err) {
        throw new Error(err)
      }
    },
    async membersList () {
      if (this.$store.state.auth.currentUser.role !== 'account_admin') return
      try {
        const { data } = await this.getMembers({
          orderType: 'desc',
          orderBy: 'created',
          take: 999
        })
        this.usersList = data.members.map(item => ({
          ...item,
          firstName: item.user_first_name,
          lastName: item.user_last_name,
          uuid: item.user_uuid
        }))
      } catch (err) {
        throw new Error(err)
      }
    },
    async callflowList () {
      try {
        const { data } = await this.getCallflows()
        this.callflowsList = data.payload.items
      } catch (err) {
        throw new Error(err)
      }
    },
    fullName (firstName, lastName) {
      return firstName + ' ' + lastName
    },
    async add (value) {
      this.$snotify.notifications = []
      if (this.$route.path === '/mailboxes') {
        try {
          const url = value.added ? 'deleteUserFromMailbox' : 'addUserToMailbox'
          const { data } = await this[url]({
            mailboxUuid: this.selectedMailbox.uuid,
            userUuid: value.uuid
          })
          this.setSelectedMailbox(data.mailbox)
        } catch (err) {
          this.$snotify.error(err.message)
          throw new Error(err)
        }
      } else if (this.callflowPage) {
        try {
          await this.$store.dispatch('callflow/getCallflow', value)
        } catch (err) {
          throw new Error(err)
        }
      } else if (this.$route.path === '/phone-numbers') {
        if (!this.selectedDidNumber) return
        try {
          let message = ''
          let userId = value.user_id
          if (this.selectedDidNumber.userId !== userId) {
            await this.assignNumberToMember({
              userId,
              didUuid: this.selectedDidNumber.uuid
            })
            message = 'Number assigned to member'
          } else {
            await this.releaseUserNumber({
              userId,
              didUuid: this.selectedDidNumber.uuid
            })
            message = 'Number unassigned from member'
            userId = 0
          }
          this.setSelectedDidNumber({
            ...this.selectedDidNumber,
            userId
          })
          this.$snotify.success(message)
          this.membersList()
        } catch (err) {
          this.$snotify.error(err.message)
          throw new Error(err)
        }
      }
    },
    async addAssign (id) {
      // this.usersList[index].extension = this.extension
      // this.extension = ''

      console.log(id)
    },
    close () {
      this.$store.dispatch('components/RightPopUpMyContacts', false)
    }
  }
}
</script>

<style scoped lang="scss">
  ::v-deep.v-menu__content {
    border: 1px solid #c4cdd5 !important;
    contain: initial;
    overflow: visible;

    &:before {
      position: absolute;
      content: "";
      top: 0;
      right: 10px;
      transform: translateY(-100%);
      width: 8px;
      height: 8px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #fff;
      z-index: 9;
    }

    &:after {
      position: absolute;
      content: "";
      top: 0;
      right: 8px;
      transform: translateY(-100%);
      width: 10px;
      height: 10px;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 9px solid #bac2c9;
    }
  }

  .popup-wrapper {
    margin-top: 119px;
  }
  .search-box {
    height: 36px;
    ::v-deep .v-label {
      color: #919EAB !important;
      line-height: 1.2;
    }
  }
  @media (max-width: 960px) {
    .popup-wrapper {
      position: fixed;
      inset: 0;
      z-index: 100;
      display: grid;
      place-items: center;
    }
  }
</style>
