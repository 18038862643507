import { Howl } from 'howler'

const PATH_RING = './telephone-ring-04.mp3'
let isPlaying = 0
const audioIncomingCall = new Howl({
  src: [PATH_RING],
  autoplay: false,
  loop: true
  // volume: 0.5
})

const checkUserActivity = () => {
  playAudioRing()
}
const playAudioRing = () => {
  window.removeEventListener('mousemove', checkUserActivity)
  isPlaying++
  if (isPlaying === 1) {
    isPlaying = true
    try {
      audioIncomingCall.play()
    } catch (error) {
      isPlaying = 0
      isPlaying = false
      window.addEventListener('mousemove', checkUserActivity)
    }
  }
}
const stopAudioRing = () => {
  audioIncomingCall.stop()
  isPlaying = 0
}

export {
  playAudioRing,
  stopAudioRing
}
