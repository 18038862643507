<template>
  <v-app-bar
    app
    color="#1C2260"
    elevation="0"
    dark
    height="56px"
    class="pl-9 pl-md-4 z-index-100 d-none d-md-block"
    :style="{ left: isMenuCollapsed ? '56px' : '300px' }"
  >
    <v-col
      lg="8"
      md="5"
      class="d-flex pl-0 py-0"
    >
      <SearchDropdown
        :isHeader="true"
      />
    </v-col>

    <v-col
      md="7"
      lg="4"
      class="d-flex py-0 pr-0"
    >
<!--      <v-icon size="36px" class="mt-0 d-none d-md-block">account_circle</v-icon>-->
      <div
        v-if="$store.state.auth.currentUser.role === Role.CompanyAdmin"
        class="d-md-flex flex-column align-center justify-end ml-auto d-none mr-2 cursor-pointer"
        @click="addBalanceDialog = true"
      >
        <span class="fz-12 text-pre">Balance</span>
        <span class="fz-16 font-weight-semi-bold text-pre">${{ balance }}</span>
      </div>

      <div class="d-md-flex flex-column align-center justify-end ml-auto d-none">
        <span class="fz-12 text-pre">{{ localDate() }}</span>
        <span class="fz-16 font-weight-semi-bold text-pre">{{ currentTime }}</span>
      </div>

      <v-menu
        bottom
        left
        max-width="205px"
        nudge-left="132px"
        nudge-bottom="-3px"
        :rounded="'1'"
        offset-y
        :close-on-content-click="true"
        content-class="elevation-2 v-menu--triangle"
        v-model="closeMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-if="$store.state.auth.currentUser"
            style="border-left: 1px solid #000639;"
            class="my-n1 ml-4 pl-4 text-none text-left overflow-hidden"
            v-bind="attrs"
            v-on="on"
            text
            tile
            height="56px"
          >
            <v-list-item-avatar min-width="32px" width="32px" height="32px">
              <v-img
                :alt="firstName + ' avatar'"
                :src="loadingImage ? '' : !loadingImage && userImage ? userImage : require('../assets/default-user-image.jpg')"
              ></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="fz-14 font-weight-medium">
                {{ fullName(firstName, lastName) }}
              </v-list-item-title>
              <v-list-item-subtitle class="fz-12 text-capitalize" style="color: #B3B5CB;">
                {{ $store.state.auth.currentUser.role.replace('_', ' ') }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-btn>
        </template>

        <user-menu @closeMenu="onClickCloseMenu"/>
      </v-menu>
    </v-col>
    <add-balance-dialog
      :show="addBalanceDialog"
      :balance="balance"
      @close="addBalanceDialog = false"
    />
  </v-app-bar>
</template>

<script>
import UserMenu from '@/components/UserMenu'
import SearchDropdown from '@/components/SearchDropdown'
import { mapGetters, mapActions } from 'vuex'
import { Role } from '@/_helpers/role'
import AddBalanceDialog from '@/components/AddBalanceDialog'

export default {
  name: 'HeaderApp',
  components: { SearchDropdown, UserMenu, AddBalanceDialog },
  data: () => ({
    closeMenu: false,
    avatar: 'https://picsum.photos/200',
    currentTime: null,
    loadingImage: false,
    firstName: '',
    lastName: '',
    Role,
    balance: 0,
    addBalanceDialog: false
  }),
  computed: {
    ...mapGetters([
      'userImage'
    ]),
    ...mapGetters('components', ['isMenuCollapsed'])
  },
  created () {
    this.getData()
    this.loadImage()
  },
  methods: {
    ...mapActions([
      'getAccountData',
      'getAccountImage'
    ]),
    async getData () {
      try {
        this.loading = true

        const { data } = await this.getAccountData(this.$store.state.auth.currentUser.role)
        if (!data) return

        this.firstName = data.firstName
        this.lastName = data.lastName
        this.balance = data.balance
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async loadImage () {
      try {
        this.loadingImage = true
        if (!this.userImage) await this.getAccountImage({ responseType: 'arraybuffer' })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loadingImage = false
      }
    },
    onClickCloseMenu (val) {
      this.closeMenu = val
    },
    fullName (firstname, lastname) {
      return firstname + ' ' + lastname
    },
    localDate () {
      return new Date().toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      })
    },
    localTime () {
      this.currentTime = new Date().toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      })

      const self = this
      setInterval(function () {
        self.currentTime = new Date().toLocaleString('en-US', {
          hour: 'numeric',
          minute: 'numeric',
          hour12: true
        })
      }, 60000)
    }
  },
  mounted () {
    this.localTime()
  }
}
</script>
<style scoped lang="scss">
  .z-index-100 {
    z-index: 100 !important;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
