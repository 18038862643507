<template>
  <transition>
    <div class="mt-5">
     <template v-if="activeIncomingCalls.length !== 0">
        <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">
          Active Calls
        </div>

        <IncomingCallsItem
          v-for="(call, callIndex) in activeIncomingCalls"
          :key="callIndex + 'active'"
          :callItem="call"
          :callItemIndex="callIndex"
          @answerCall="answer(call)"
          @hangupCall="hangupCall(call)"
          @holdCall="hold(call)"
          @addCall="add"
          @sendCall="send(call)"
          @muteCall="mute(call)"
          @recordCall="record(call)"
          @markAsSpam="markAsSpam(call)"
          @markSpamAndHungUp="markSpamAndHungUp(call)"
        />
      </template>
      <template v-if="nonActiveIncomingCalls.length !== 0">
        <div class="fz-12 font-weight-semi-bold text-uppercase mb-2">
          Incoming Calls
        </div>

        <IncomingCallsItem
          v-for="(call, callIndex) in nonActiveIncomingCalls"
          :key="callIndex"
          :callItem="call"
          :callItemIndex="callIndex"
          @answerCall="answer(call)"
          @hangupCall="hangupCall(call)"
          @addCall="add"
          @sendCall="send(call)"
          @recordCall="record(call)"
          @markAsSpam="markAsSpam(call)"
          @markSpamAndHungUp="markSpamAndHungUp(call)"
        />
      </template>
    </div>
  </transition>
</template>

<script>
import IncomingCallsItem from '@/components/IncomingCallsItem'

import {
  callHangup,
  callDecline,
  callMute,
  callHold,
  getSessionById,
  OUTGOING_REQUEST_NAME,
  callAnswer
} from '../utilities/sessionManager'
import { mapActions } from 'vuex'

export default {
  name: 'IncomingCalls',
  components: { IncomingCallsItem },
  data: () => ({
    calls: []
  }),
  computed: {
    getAllCalls () {
      return this.$store.state.components.calls
    },
    incomingCalls () {
      return this.getAllCalls.filter(call => call.type !== OUTGOING_REQUEST_NAME)
    },
    activeIncomingCalls () {
      return this.incomingCalls.filter(call => call.active)
    },
    nonActiveIncomingCalls () {
      return this.incomingCalls.filter(call => !call.active)
    }
  },
  created () { },
  methods: {
    ...mapActions(['recordCall', 'markSpamCall', 'markSpamAndHangUpCall']),
    async answer (call) {
      const s = getSessionById(call.id)
      callAnswer(s)
    },
    add (item) {
      console.log('Added incoming call, CALLING ID: ' + item.id)
    },
    send (call) {
      if (this.$route.name !== 'Chat' || +this.$route.query?.n !== +call.phoneNumber) {
        this.$router.push({ name: 'Chat', query: { n: call.phoneNumber } })
      }
      console.log('Sent incoming call, CALLING ID: ' + call.id)
    },
    mute (call) {
      const s = getSessionById(call.id)
      const mute = !call.mute
      this.$store.commit('components/muteCall', {
        id: call.id,
        mute: mute
      })
      callMute(mute, s)
    },
    async hold (call) {
      const s = getSessionById(call.id)
      const _hold = !call.hold
      const res = await callHold(_hold, s)
      if (res === 'success') {
        this.$store.commit('components/holdCall', {
          id: call.id,
          hold: _hold
        })
      }
    },
    async record (call) {
      try {
        const session = getSessionById(call.id)
        const action = call.record ? 'stop' : 'start'
        const { data } = await this.recordCall({ uuidActiveCall: call.uuidActiveCall, action })
        console.log(data)
        this.$store.commit('components/updateCall', {
          id: session.id,
          record: !call.record
        })
        console.log(`Recording incoming call: ${action}, CALLING ID: ${call.id}`)
      } catch (error) {
        console.error(error)
      }
    },
    async markAsSpam (call) {
      try {
        const session = getSessionById(call.id)
        const action = !call.is_spam
        const { data } = await this.markSpamCall({ uuidActiveCall: call.uuidActiveCall, action })
        console.log(data)
        this.$store.commit('components/updateCall', {
          id: session.id,
          is_spam: !call.is_spam
        })
        console.log(`Incoming call marked as: ${action ? 'spam' : 'unspam'}, CALLING ID: ${call.id}`)
      } catch (error) {
        console.error(error)
      }
    },
    async markSpamAndHungUp (call) {
      try {
        await this.markAsSpam(call)

        const session = getSessionById(call.id)

        if (!session || !session.id) return

        this.$store.commit('components/updateCall', {
          id: session.id,
          is_spam: !call.is_spam
        })

        this.hangupCall(call)
      } catch (error) {
        this.hangupCall(call)
        console.error(error)
      }
    },
    hangupCall (call) {
      const s = getSessionById(call.id)
      call.active ? callHangup(s) : callDecline(s)
    }
  }
}
</script>
